import { formatDistanceToNow, parseISO } from "date-fns";
import * as Sentry from "@sentry/browser";
import ApiService from "@/common/api.service";

export function getParameters() {
  return JSON.parse(document.querySelector("html").dataset.userParameters);
}

export function makeAPIURLsRelative(obj, host, ignoreKeys) {
  /*
   * Strips the hostname from API URLs in a JSON object, making them relative.
   *
   * Useful for comparing relative API URLs generated within backend code to URLs
   * returned by API requests.
   *
   * Note: modification is currently done in-place.
   *
   * Arguments:
   *   obj: The JSON object to recursovely search for API URLs
   *   host: Optional. The search string to determine if something if an API URL.
   *     If not specified, an attempt will be made to determine it from the
   *     top-level "url" key of the JSON object.
   *   ignoreKeys: A list of keys to not modify. keys can be at any depth, and will
   *     be checked at all depths.
   *
   * Returns:
   *   The JSON object with the hostname recursively removed from any detected API
   *   URLs.
   */
  if (ignoreKeys === undefined) {
    ignoreKeys = [];
  }
  if (!host && obj.url) {
    // If not hostname provided and the object has a URL field, determine the
    // search string by parsing the JSON object's "url" field. Note we include
    // "/api/" in order to only identify API URLs in our search.
    let url = new URL(obj.url);
    host =
      url.protocol + "//" + url.hostname + (url.port ? ":" + url.port : "") + "/api/";
  }
  // For each field in the returned JSON
  for (const key in obj) {
    // If it's the "url" field or another string field that starts with the API
    // URL search string, extract from the field value, only the pathname of the
    // URL. This will include the "/api/" in the resultant string since we are
    // not using the search string to find/replace, only match.
    // Note that if obj is a list of URLs (e.g. an unexpanded m2m relation), this
    // will catch it when it's recursively passed to this function (in which case
    // the keys will be list indices).
    // Note: If a url field starts with "/" it's considered already relative.
    if (ignoreKeys.includes(key)) {
      continue;
    } else if (
      (key == "url" && obj[key] && !obj[key].startsWith("/")) ||
      (obj[key] && typeof obj[key] === "string" && obj[key].startsWith(host))
    ) {
      obj[key] = new URL(obj[key]).pathname;
    } else if (obj[key] && typeof obj[key] === "object") {
      // If it's an object (list or dict), recursively process the contents
      makeAPIURLsRelative(obj[key], host, ignoreKeys);
    }
  }
  return obj;
}

export function getQueryArgsFromNextURL(url) {
  // Parse the URL
  let nextURL = new URL(url);
  let queryArgs = {};
  // Iterate over the query args (so that we don't lose any duplicates)
  for (const [key, value] of nextURL.searchParams.entries()) {
    // Check if we already have a value for this key
    if (key in queryArgs) {
      // If this entry is not already stored as an array, convert it as this
      // is the format expected by drf-serializer-extensions
      if (!Array.isArray(queryArgs[key])) {
        queryArgs[key] = [queryArgs[key]];
      }
      // Add new value to the array
      queryArgs[key].push(value);
    } else {
      // Add it as just a key:value pair
      queryArgs[key] = value;
    }
  }
  return queryArgs;
}

export function getLanguageCode() {
  return document.documentElement.lang;
}

export function getTimeZone() {
  return document.documentElement.dataset?.timeZone;
}

export function niceDateTime(api_datetime_string, { daysBeforeAbsolute = null } = {}) {
  let date = parseISO(api_datetime_string);
  let current_date = new Date();
  let difference_days = (current_date - date) / 1000 / 60 / 60 / 24;
  if (daysBeforeAbsolute != null && difference_days > daysBeforeAbsolute) {
    return date.toLocaleString(getLanguageCode(), {
      timeZone: getTimeZone(),
      timeZoneName: "short",
    });
  } else {
    return `${formatDistanceToNow(date)} ago`;
  }
}

export function isDeepEmpty(obj) {
  // Returns whether the nested object contains only empty objects
  // Note: Will crash for Maps.
  if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (!isDeepEmpty(obj[key])) {
        return false;
      }
    }
    return true;
  }

  // Empty objects/arrays will be caught by the above.
  return obj === null;
}

/**
 * Configure print buttons to open the print preview dialog
 */
export function configurePrintButtonEvents() {
  for (const el of document.querySelectorAll(".print-trigger")) {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      window.print();
    });
  }
}

/**
 * Calls a function when the DOM content has loaded.
 *
 * If the DOM content has already loaded, the function is called immediately.
 *
 * @param {(input: any) => null} fn - The function to call
 */
export function runOnDOMContentLoaded(fn) {
  /*
   * Note, per MDN, there is no race condition here. It's not possible for the document
   * to be loaded between the if check and the addEventListener() call. JavaScript has
   * run-to-completion semantics, which means if the document is loading at one
   * particular tick of the event loop, it can't become loaded until the next cycle, at
   * which time the doSomething handler is already attached and will be fired.
   */
  if (document.readyState == "loading") {
    document.addEventListener("DOMContentLoaded", fn);
  } else {
    fn();
  }
}

/**
 * A helped function to generate a dictionary that clears common Highcharts style
 * options so that CSS classes take precedence.
 *
 * This is useful if you want to disable Highcharts `styledMode`, but want some aspects
 * to still use the CSS classes we have defined.
 *
 * @returns {Object} An object with common style options set to null
 */
export function clearHighchartsDefaultStyles() {
  return {
    // Clear the defaults added by Highcharts. We have applied styles via CSS in
    // `generic-highcharts.scss`
    fontFamily: null,
    fontWeight: null,
    fontSize: null,
    fill: null,
    color: null,
  };
}

/**
 * A function that returns the value of a nested field in a object, from a string
 * containing a dotted path value.
 *
 * Assumes that keys inside objects do not contain dots.
 *
 * @param {Object} data - The object to extract the value from.
 * @param {String} path - The dotted path to the value to extract.
 * @returns {*} - The value at the dotted path in the object. If the path does not
 * exist, `null` is returned.
 */
export function getDataFromDottedPath(data, path) {
  return path
    .split(".")
    .reduce((obj, key) => (obj && obj.hasOwnProperty(key) ? obj[key] : null), data);
}

/**
 * A helper function to determine if a page number is valid.
 *
 * @param {number} page - The page number to check
 * @returns {boolean} Whether the page number is valid
 */
export function validPageNumber(page) {
  return page && !isNaN(page) && page > 0 && page !== null;
}

/**
 * A helper function to determine if a id number is valid.
 *
 * @param {number} id - The id number to check
 * @returns {boolean} Whether the id number is valid
 */
export function validResourceId(id) {
  return id && (typeof id === "string" || (!isNaN(id) && id > 0)) && id !== null;
}

/**
 * Fetch all items in every page for given url entity in database
 *
 * If you need to retrieve all items from a database entity and they are paginated, this
 * will cycle through all pages and retrieve the whole list of desired items at the
 * maximum per-page amount. It also handles errors for edge cases related to both the
 * recursive nature of the function and the possibility that items may be manipulated
 * while the api is being queried.
 *
 * @param {String} apiUrl - The API endpoint to query (relative to the `ApiService`
 * base URL).
 * @param {Object} options Additional options for the query.
 * @param {Object} options.apiQueryArgs An object containing additional parameters to
 * include in the API call as query arguments. These should be refs if the query should
 * be reactive to changes in them.
 * @param {String} options.itemsKey - Allows for flexibility of bracket notation in accessing
 * the returned lists items
 * @param {String} options.pkKey - Allows for flexibility of bracket notation in accessing
 * the returned lists items primary keys
 * @param {Number} options.perPage - The number of items to be returned in the list for each
 * page, set to maximum since we want all items
 * @param {Object} options.apiServiceConfig - An object that holds axios api configuration options
 * @returns {Object} An object with the collated items and their count
 */
export async function fetchAllPages(
  apiUrl,
  {
    apiQueryArgs = {},
    itemsKey = "items",
    pkKey = "id",
    perPage = 250,
    apiServiceConfig = null,
  } = {},
  { page = 1, _result = null } = {},
) {
  if ((page != 1 && _result === null) || (_result !== null && page == 1)) {
    const error = new Error("fetchAllPages called with incorrect arguments");
    Sentry.captureException(error, { extra: { apiUrl, page } });
    throw error;
  }

  const { data } = await ApiService.query(
    apiUrl,
    {
      page: page,
      limit: perPage,
      ...apiQueryArgs,
    },
    apiServiceConfig,
  );

  if (page == 1) {
    _result = data;
  } else {
    _result[itemsKey] = _result[itemsKey].concat(data[itemsKey]);
    // Make sure the number of items didn't change between pages. If it did, we have no
    // way of knowing whether we missed an item or got a duplicate item. SO we throw an
    // error.
    if (_result.count != data.count) {
      const error = new Error("Data changed during multi-page fetch");
      Sentry.captureException(error, { extra: { apiUrl, page } });
      throw error;
    }
  }

  if (_result[itemsKey].length === _result.count) {
    const ids = new Set(_result[itemsKey].map((item) => item[pkKey]));
    // Make sure we didn't get any duplicate entries. If we did, that likely means we
    // had a deletion and addition between pages (such that the total count stayed
    // constant). However, the presence of a duplicate implies we missed one, so we need
    // to throw an error.
    if (ids.size !== _result[itemsKey].length) {
      const error = new Error("Duplicate items fetched during multi-page fetch");
      Sentry.captureException(error, { extra: { apiUrl, page } });
      throw error;
    }
    return _result;
  }

  return fetchAllPages(
    apiUrl,
    {
      apiQueryArgs,
      itemsKey,
      perPage,
      apiServiceConfig,
    },
    {
      page: page + 1,
      _result,
    },
  );
}
